/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { Pannellum } from 'pannellum-react';
import {isMobile} from 'react-device-detect';
import { addDefaultSrc } from "helpers/onErrors";
import { actionDetailStand } from 'store/hall/actions';
import ShowModal from 'components/atoms/Modals/showModal';
import linkEmbeded from 'helpers/linkEmbeded';
import forceRedirect from '../../helpers/location';
import BottomNavbar from 'components/atoms/Button/BottomNavbar';
import bgloading from 'assets/images/BG_loading.png';

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";


import { actionGetListHall, actionGetMainHall, actionSetups } from 'store/hall/actions';
import ButtonChat from 'components/molecules/MainHall/ButtonChat';
import ButtonBack from 'components/molecules/MainHall/ButtonBack';

import GalleryImage from 'assets/images/gallery.gif';
import  environment  from 'environment';


const images = [
  "//placekitten.com/1500/500",
  "//placekitten.com/4000/3000",
  "//placekitten.com/800/1200",
  "//placekitten.com/1500/1500"
];


export default function Stand() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const panImage = useRef(null);
  const [detailStand, setDetailStand] = useState({});
  const [defaultValueView, setDefaultValueView] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenLight, setIsOpenLight] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isRender, setIsRender] = useState(false);
  const [isSrc, setSrc] = useState('');
  const [type, setType] = useState('');


  
  useEffect(() => {
    dispatch(
      actionDetailStand(id, val => {
        setDefaultValueView(val.defaultCoordinate);
        setDetailStand(val);
      }));

      dispatch(
        actionGetListHall()
      );

  }, []);
  
  const handleClick = (event, arg) => {
    if (arg.type === '1') {
      // move page
      forceRedirect(`/main-hall/${arg.childMainHallId}`);
      return;
    } else if (arg.type === '2') {
      // show pdf
      setType(arg.type);
      setSrc(arg.link);
      setIsOpen(!isOpen);
    } else if (arg.type === '4') {
      // youtube
      setType(arg.type);
      let l = '//www.youtube.com/embed/'+linkEmbeded(arg.link)+'?autoplay=1';
      setSrc(l);
      setIsOpen(!isOpen);
    } else if (arg.type === '5') {
      // banner
      // environment.url
      setType(arg.type);
      setSrc(environment.url + arg.link);
      setIsOpen(!isOpen);
    } else if (arg.type === '6') {
      // external link
      window.open(arg.link);
    } else if (arg.type === '7') {
      // move page
      setIsRender(!isRender);
      return forceRedirect(`/stand/${arg.childMainHallId}`);
    }
  };

  const handleClickPopUp = () =>  {
    setIsOpenLight(true);
  };

  const hotspotIcon = (hotSpotDiv, args) => {
    // const image = document.createElement("img");
    // image.classList.add("image");
    // image.setAttribute("width", Number(args.width) + Number(100));
    // image.setAttribute("height", Number(args.height) + Number(100));
    // image.setAttribute("src", args.image);
    // image.setAttribute("onError", addDefaultSrc);
    // image.style.width = image.scrollWidth - 20 + 'px';
    // image.style.marginLeft = -(image.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    // image.style.marginTop = -image.scrollHeight - 12 + 'px';

    // hotSpotDiv.appendChild(image);
    // hotSpotDiv.style.width = hotSpotDiv.scrollWidth - 100 + "px";


    // const image = document.createElement("img");
    // image.classList.add("image");
    // image.setAttribute("width", Number(args.width) + Number(100));
    // image.setAttribute("height", Number(args.height) + Number(100));
    // image.setAttribute("src", args.image);
    // image.setAttribute("onError", addDefaultSrc);
    // image.style.width = image.scrollWidth - 20 + 'px';
    // image.style.marginLeft = -(image.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    // image.style.marginTop = -image.scrollHeight - 12 + 'px';

    // hotSpotDiv.appendChild(image);
    // hotSpotDiv.style.width = hotSpotDiv.scrollWidth - 100 + "px";


    hotSpotDiv.classList.add('pnlm-hotspot-custom');
    var span = document.createElement('span');
    let x = Number(args.width);
    let y = Number(args.height);
    span.innerHTML = '<div class="videoWrapper"><img style="width:'+x+'px;height:'+y+'px" src="'+args.image+'" onError="'+addDefaultSrc+'" /></div>';
    hotSpotDiv.appendChild(span);
    span.style.width = span.scrollWidth - 20 + 'px';
    // span.style.marginLeft = -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    // span.style.marginTop = -span.scrollHeight - 12 + 'px';

    // w 176px
    // h 277px 

    // var image = document.createElement('img');
    // image.classList.add("image");
    // image.setAttribute("width", Number(args.width));
    // image.setAttribute("height", Number(args.height));
    // image.setAttribute("src", args.image);
    // image.setAttribute("onError", addDefaultSrc);
    // // image.style.width = image.scrollWidth - 20 + 'px';
    // // image.style.marginLeft = -(image.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    // // image.style.marginTop = -image.scrollHeight - 12 + 'px';

    // // hotSpotDiv.appendChild(image);
    // // hotSpotDiv.style.width = hotSpotDiv.scrollWidth - 100 + "px";
    
    // hotSpotDiv.classList.add('custom-tooltip');
    // var span = document.createElement('span');
    // span.innerHTML = args.title;
    // hotSpotDiv.appendChild(span);
    // span.style.width = span.scrollWidth - 20 + 'px';
    // span.style.marginLeft = -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    // span.style.marginTop = -span.scrollHeight - 12 + 'px';

    // hotSpotDiv.appendChild(image);


  };


  const hotspotGallery = (hotSpotDiv, args) => {

    hotSpotDiv.classList.add('pnlm-hotspot-custom');
    var span = document.createElement('span');
    let x = Number(args.width);
    let y = Number(args.height);
    span.innerHTML = '<div class="videoWrapper"><img style="width:86px;height:85px" src="'+GalleryImage+'" onError="'+addDefaultSrc+'" /></div>';
    hotSpotDiv.appendChild(span);
    span.style.width = span.scrollWidth - 20 + 'px';

  };

  const hotSpotVideo = (hotSpotDiv, arg) => {
    // hotSpotDiv.classList.add('pnlm-hotspot-custom');
    // var span = document.createElement('span');
    // if (arg.type == "8") {
    //   span.innerHTML = '<div class="videoWrapper"><iframe width="'+arg.width+'px" height="'+arg.height+'px" src="'+arg.link+'"></iframe></div>';   
    // } else {
    //   span.innerHTML = '<div class="videoWrapper"><iframe width="'+arg.width+'px" height="'+arg.height+'px" src="//www.youtube.com/embed/'+linkEmbeded(arg.link)+'?autoplay=1"></iframe></div>';
    // }
    // hotSpotDiv.appendChild(span);
    // span.style.width = span.scrollWidth - 20 + 'px';
    // span.style.marginLeft = -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    // span.style.marginTop = -span.scrollHeight - 12 + 'px';

    hotSpotDiv.classList.add('pnlm-hotspot-custom');
    var span = document.createElement('span');
    if (arg.type == "9") {
      span.innerHTML = '<div class="videoWrapper"><iframe style="width:'+arg.width+'px;height:'+arg.height+'px" src="'+arg.link+'"></iframe></div>';   
    } else {
      span.innerHTML = '<div class="videoWrapper"><iframe style="width:'+arg.width+'px;height:'+arg.height+'px" src="//www.youtube.com/embed/'+linkEmbeded(arg.link)+'?autoplay=1"></iframe></div>';
    }
    hotSpotDiv.appendChild(span);
    span.style.width = span.scrollWidth - 20 + 'px';
    span.style.marginLeft = -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    span.style.marginTop = -span.scrollHeight - 12 + 'px';

  };

  
  let imageOri = detailStand.imgBanner;
  if (detailStand.imgBanner !== undefined) {
    if (isMobile) {
      imageOri = detailStand.imgBannerChild;
    }
  }

  let listProd = [];

  return (
    <Fragment>
       <Pannellum
        id='tester'
        type="equirectangular"
        compass={true}
        preview={bgloading}
        width='100%'
        height='100vh'
        image={ imageOri }
        ref={ panImage }
        pitch={ defaultValueView?.defaultPitch }
        yaw={ defaultValueView?.defaultYaw }
        mouseZoom={ false }
        title={detailStand?.label}
        autoLoad
        showZoomCtrl={ false }
        draggable={true}
        doubleClickZoom={false}
        onMouseup={ event => {
          // console.log(
          //   'PITCH',
          //   panImage.current.getViewer().mouseEventToCoords(event)[0]
          // );
          // console.log(
          //   'YAW',
          //   panImage.current.getViewer().mouseEventToCoords(event)[1]
          // );
        } }
      >
         { detailStand.mainHall?.map((val, i) => {

         
          if (val.company == 'Prodbanner') {
            
            listProd.push(environment.url + val.link);
            return(
            <Pannellum.Hotspot
              key={ i }
              type='custom'
              pitch={ '-10.7321266659437' }
              yaw={ '127.52955199891421' }
              text={ val.description }
              tooltip={ hotspotGallery }
              tooltipArg={ listProd }
              handleClick={ handleClickPopUp }
            />);
          }
          

          if (val.type === 3) {
            return (
              <Pannellum.Hotspot
                key={ i }
                type='info'
                pitch={ val.coordinate.pitch }
                yaw={ val.coordinate.yaw }
                text={ val.description }
              />
            );
          }
          if (val.type === "9") { 
            return (
              <Pannellum.Hotspot
                key={ i }
                type='custom'
                pitch={ val.coordinate.pitch }
                yaw={ val.coordinate.yaw }
                text={ val.description }
                tooltip={hotSpotVideo}
                tooltipArg={val}
              />
            );
          }

        


          return (
            <Pannellum.Hotspot
              key={ i }
              type='custom'
              pitch={ val.coordinate?.pitch }
              yaw={ val.coordinate?.yaw }
              tooltip={ hotspotIcon }
              tooltipArg={ val }
              text={ i }
              handleClick={ handleClick }
              handleClickArg={ val }
            />
          );
        }) }
      </Pannellum>
      
      <ShowModal
        isOpen={ isOpen }
        setIsOpen={ () => setIsOpen(!isOpen) }
        className={ type === '5' ? 'max-w-[70%] my-4' : 'max-w-[90%] my-4' }
        setIsRender={ () => setIsRender(!isRender) }
        type={ type }
        isSrc={ isSrc }
      />
        <ButtonBack
          liveChat={detailStand.liveChat}
        />
        
        <ButtonChat
          liveChat={detailStand.liveChat}
        />
        <BottomNavbar />

        
        {isOpenLight && (
          <Lightbox
            mainSrc={listProd[photoIndex]}
            nextSrc={listProd[(photoIndex + 1) % listProd.length]}
            prevSrc={listProd[(photoIndex + listProd.length - 1) % listProd.length]}
            onCloseRequest={() => {
              console.log(isOpenLight);
              setIsOpenLight(false);
              console.log(isOpenLight);
            }}
            onMovePrevRequest={() =>{
              let y =  (photoIndex + listProd.length - 1) % listProd.length;
              setPhotoIndex(y);
              
            }}
            onMoveNextRequest={() => {
              console.log(listProd);
              let x = (photoIndex + 1) % listProd.length;
              setPhotoIndex(x);
            }
            }
          />
        )}

    </Fragment>
  );
}

/* eslint-disable no-undef */
import { ServicesAuth } from 'services';
import Cookies from 'js-cookie';
import { SAVE_DATA_LOGIN, SAVE_IP_USERS } from './actionTypes';


export const actionGoogleSignIn = (data: any, callBack: any) => async() => {
    const response = await ServicesAuth.googleSignIn(data);
    if (response !== undefined) {
      const tokenBase64 = btoa(response.sessionId);
      Cookies.set('token', tokenBase64);
      Cookies.set('user', response.customer);
      callBack('/main-hall');
    }
};

export const actionLogout = (callBack: any) => async() => {
    const response = await ServicesAuth.signout();
    if (response !== undefined) {
      callBack('/sign-in');
    }
};

export const actionGetLogin = (userIP: any, callback: any) => async(dispatch: any) => {
    const response = await ServicesAuth.getLogin(userIP);
    if (response !== undefined) {
      dispatch({
        type: SAVE_DATA_LOGIN,
        payload: response.data[0]
      });
      callback(response.data[0]);
    }
};

export const actionGetIP = callback => async(dispatch: any) => {
  const response = await ServicesAuth.getIP();
  if (Object.keys(response).length > 0) {
    dispatch({
      type: SAVE_IP_USERS,
      payload: response.IPv4
    });
    callback(response);
  }
  callback(response);

};
import { toast } from 'react-toastify';

import { customFetch } from 'helpers';
import environment from 'environment';

export const googleSignIn = async data => {
  try {
    const apiFetch: any = await customFetch(`${environment.api?.signin}/public`, 'POST', data, false);
    return apiFetch;
  } catch (error) {
    toast.error('Opsss. Something When Wrong !!!');
    throw error;
  }
};

export const signout = async() => {
  try {
    const apiFetch: any = await customFetch(`${environment.api?.public}/logout`, 'GET', false, false);
    return apiFetch;
  } catch (error) {
    toast.error('Opsss. Something When Wrong !!! tes');
    throw error;
  }
};

export const getLogin = async userIP => {
  try {
    const apiFetch: any = await customFetch(`${environment.api?.public}/main-hall/login?userIP=`+userIP, 'GET', false, false, userIP);
    return apiFetch;
  } catch (error) {
    toast.error('Opsss. Something When Wrong !!! tes');
    throw error;
  }
};

export const getIP = async() => {
  try {
    const apiFetch: any = await customFetch(`${environment.api?.ip}`, 'GET', false, false);
    return apiFetch;
  } catch (error) {
    // toast.error('Opsss. Something When Wrong !!! tes');
    return false;
    throw error;
  }
};

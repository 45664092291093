/* eslint-disable react/jsx-no-bind */
import  environment  from 'environment';
import MyModal from 'components/atoms/Modals';

export default function ModalMultiType(props) {
  const { isOpen, setIsOpen, className, setIsRender, type, isSrc } = props;
  const showPDF = environment.url + isSrc;
  
  return (
    <MyModal isOpen={ isOpen } closeModal={ setIsOpen } className={ className }>
      <div className='flex justify-end p-4'>
        <div
          className='cursor-pointer'
          onClick={ () => {
            setIsOpen();
            setIsRender();
          } }
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-10 w-10'
            fill='none'
            viewBox='0 0 24 24'
            stroke='#9CA3AF'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={ 2 }
              d='M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z'
            />
          </svg>
        </div>
      </div>
      {
        type === '5' ? (
          <div className='p-3'>
            <div className='h-full'>
              <img src={ `${environment.url}${isSrc}` } className='w-full h-full' />
            </div>
          </div>
        ) : (
          <div className='pt-[59%] relative w-full overflow-hidden '>
            <iframe
              src={ type === '2' ? showPDF : isSrc + '?autoplay=1&mute=1' }
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
              className='w-full h-full absolute top-0 bottom-0 right-0 left-0'
            />
          </div>
        )
      }
    </MyModal>
  );
}
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import LoadingBar from 'assets/images/loading-chat.gif';
import imgChat from 'assets/images/chat.gif';

export default function ButtonChat(props) {
  const { liveChat } = props;
  const elementChat = useSelector(state => state.MainHallReducer.data);


  const handleClick = () => {

    var btnchat = document.getElementsByClassName('btnChat')[0];  
    btnchat.innerHTML = '<img src="'+LoadingBar+'" width="50px" height="50px" />';
      
    const script = document.createElement('script');
    // script.src = 'https://go.arena.im/public/js/arenachatlib.js?p=lpik-itb&e=lpik-itb-global';
    script.src = liveChat;
    script.async = true;
    script.onload = () => {
       btnchat.style.display = 'none';
    };
    document.body.appendChild(script);
  };
  
  return (
    <Fragment>
      <div className='absolute bottom-12 right-10'>
      <div className="arena-chat" data-publisher="lpik-itb" data-chatroom="lpik-itb-global" data-position="bottom"></div>
        <div
          className='cursor-pointer'
          onClick={ handleClick }
          >
          <div className="btnChat">
          <img src={imgChat} width={"100"} height={"100"} />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

/* eslint-disable no-undef */
/* eslint-disable quotes */
/* eslint-disable react/jsx-no-bind */
import { useEffect, useState, useRef, useCallback, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useGoogleLogin } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { Pannellum } from "pannellum-react";
import {isMobile} from 'react-device-detect';
import myImage from "../../../assets/images/sign-up-1.jpg";
import bgloading from 'assets/images/BG_loading.png';
import TxtType from "helpers/textType";
import "../style.css";
import {
  actionGetIP,
  actionGetLogin,
  actionGoogleSignIn,
} from "store/auth/actions";
import environment from "environment";
import Loading from "components/atoms/Loading";
import { addDefaultSrc } from "helpers/onErrors";
import linkEmbeded from 'helpers/linkEmbeded';
import ModalSignin from "components/molecules/SignIn/ModalSignin";
import ModalMultiType from "components/molecules/SignIn/ModalMultiType";
import ButtonChat from 'components/molecules/MainHall/ButtonChat';

export const equirectangularExample = {
  imageSource: myImage,
  equirectangularOptions: {},
};

export default function Home() {
  const history = useHistory();
  const [formData, setFormData] = useState({});
  
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenMultiType, setIsOpenMultiType] = useState(false);
  const [childData, setChildData] = useState([]);
  const [isRender, setIsRender] = useState(false);
  const [isSrc, setSrc] = useState("");
  const [type, setType] = useState("");
  const data = useSelector(state => state.authReducer.pageLogin);
 



  const [isLogin, setIsLogin] = useState(false);
 


  useEffect(() => {
    const url = window.location.href;
    const getHttps = "http";

    if (url.includes(getHttps)) {
      fetchgetIP();
    }
    fetchgetLogin("192.162.1.1");
  }, []);

  const fetchgetIP = useCallback(() => {
    dispatch(
      actionGetIP(response => {
        let userIP = "127.0.0.1";
        if (!response) {
          let userIP = response.IPv4;
        }
        fetchgetLogin(userIP);
      })
    );
  }, []);

  const fetchgetLogin = useCallback(userIP => {
    dispatch(
      actionGetLogin(userIP, response => {
        setChildData(response.mainHall);
        setIsLoading(false);
      })
    );
  }, []);

  const panImage = useRef(null);
  window.FormData = formData;
  const onSuccess = (response: any) => {
    if (response) {
      const body = {
        email: response.profileObj.email,
        name: window.FormData.name, 
        phone: window.FormData.phone,
        institution: window.FormData.institution
      };
      dispatch(
        actionGoogleSignIn(body, (url: any) => {
          history.push(url);
        })
      );
      
    }
  };
  const onFailure = response => {
    console.log(response);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    clientId: environment.google,
    onFailure,
  });

  const handleLogin = (name, phone, institution) => {
    setIsOpen(false);
    // if (!environment.isProduction) {
    //   const body = {
    //     email: "gyga@mail.com",
    //     name: name, 
    //     phone: phone, 
    //     institution: institution
    //   };
    //   setFormData(body);
    //   dispatch(
    //     actionGoogleSignIn(body, (url: any) => {
    //       history.push(url);
    //     })
    //   );
    // } else {

     

      const body = {
        name: name, 
        phone: phone, 
        institution: institution
      };
      setFormData(body);
      signIn();
    // }
  };

  const typerJS = (text) => {
    // let letter = 0;
    // console.log("tester");
    // function typeText() {
    //   if (letter < text.length) {
    //     console.log(document.getElementById("type-js"));
    //     document.getElementById("type-js").innerHTML += text.charAt(letter);
    //     letter++;
    //     let speed = Math.floor(Math.random() * 150) + 50;
    //     setTimeout(typeText, speed);
    //   }
    // }
    // typeText();

    var elements = document.getElementsByClassName('talktext');
    for (var i=0; i<elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-type');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) { new TxtType(elements[i], JSON.parse(toRotate), period);
        }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
    document.body.appendChild(css);

};

  const handleClick = val => {
    if (val.type === "8") {
      hanldeLoginClick();
    } else if (val.type === "6") {
      window.open(val.link);
    } else if (val.type === "5") {
      setType(val.type);
      setSrc(val.link);
      setIsOpenMultiType(!isOpenMultiType);
    } else if (val.type === "2") {
      setType(val.type);
      setSrc(val.link);
      setIsOpenMultiType(!isOpenMultiType);
    } else if (val.type === "4") {
      setType(val.type);
      let l = '//www.youtube.com/embed/'+linkEmbeded(val.link)+'?autoplay=1';
      setSrc(l);
      setIsOpenMultiType(!isOpenMultiType);
    } else if (val.type === "9") {
      setType(val.type);
      setSrc(val.link);
      setIsOpenMultiType(!isOpenMultiType);
    }
  };

  const hanldeLoginClick = () => {
    setIsOpen(!isOpen);
  };

  // Hot spot creation function
  const hotspotIcon = (hotSpotDiv, args) => {
    // hotSpotDiv.classList.add('pnlm-hotspot-custom');
    // let w = Number(args.width) + Number(100);
    // let h = Number(args.height) + Number(100);

    // var span = document.createElement('span');
    // span.innerHTML = '<div class="videoWrapper"><img style="width:'+w+'px;height:'+h+'px" src="'+args.image+'" onError="'+addDefaultSrc+'" /></div>';
    // hotSpotDiv.appendChild(span);
    // span.style.width = span.scrollWidth - 20 + 'px';
    // span.style.marginLeft = -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    // span.style.marginTop = -span.scrollHeight - 12 + 'px';



    const image = document.createElement("img");
    image.classList.add("image");
    image.setAttribute("width", Number(args.width) + Number(100));
    image.setAttribute("height", Number(args.height) + Number(100));
    image.setAttribute("src", args.image);
    image.setAttribute("onError", addDefaultSrc);
    image.style.width = image.scrollWidth - 20 + 'px';
    image.style.marginLeft = -(image.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    image.style.marginTop = -image.scrollHeight - 12 + 'px';

    hotSpotDiv.appendChild(image);
    hotSpotDiv.style.width = hotSpotDiv.scrollWidth - 100 + "px";



  };
  const hotSpotVideo = (hotSpotDiv, arg) => {
    hotSpotDiv.classList.add('pnlm-hotspot-custom');
    var span = document.createElement('span');
    if (arg.type == "9") {
      span.innerHTML = '<div class="videoWrapper"><iframe style="width:'+arg.width+'px;height:'+arg.height+'px" src="'+arg.link+'"></iframe></div>';   
    } else {
      span.innerHTML = '<div class="videoWrapper"><iframe style="width:'+arg.width+'px;height:'+arg.height+'px" src="//www.youtube.com/embed/'+linkEmbeded(arg.link)+'?autoplay=1"></iframe></div>';
    }
    hotSpotDiv.appendChild(span);
    span.style.width = span.scrollWidth - 20 + 'px';
    span.style.marginLeft = -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    span.style.marginTop = -span.scrollHeight - 12 + 'px';


    // hotSpotDiv.classList.add('pnlm-hotspot-custom');
    // var span = document.createElement('span');
    // console.log("asdfasd", arg.type);
    // if (arg.type == "9") {
    //   span.innerHTML = '<div class="videoWrapper"><iframe width="'+arg.width+'px" height="'+arg.height+'px" src="'+arg.link+'"></iframe></div>';   
    // } else {
    //   span.innerHTML = '<div class="videoWrapper"><iframe width="'+arg.width+'px" height="'+arg.height+'px" src="//www.youtube.com/embed/'+linkEmbeded(arg.link)+'?autoplay=1"></iframe></div>';
    // }
  
    // hotSpotDiv.appendChild(span);
    // span.style.width = span.scrollWidth - 20 + 'px';
    // span.style.marginLeft = -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    // span.style.marginTop = -span.scrollHeight - 12 + 'px';

  };

 


  let imageOri = data.imgBanner;
  if (data.imgBanner !== undefined) {
    if (isMobile) {
      imageOri = data.imgBannerChild;
    }
  }

     //hotspotChat ...
     const hotspotChat = (hotSpotDiv, args) => {
      var html = `<div class="talktext" id="type-js" data-period="2000" data-type='["${args.link}"]'></div>`;
      const chatHtml = document.createElement("div");
      chatHtml.className = "talk-bubble tri-right border round left-top";
      chatHtml.innerHTML = html;
      hotSpotDiv.appendChild(chatHtml);
      typerJS(args.link);
      // window.addEventListener("DOMContentLoaded", typerJS);

    };



  return (
    <>
      { isLoading ? (
        <Loading />
      ) : (
        <Pannellum
          id='tester'
          type="equirectangular"
          autoRotate={-2}
          compass={true}
          preview={bgloading}
          width='100%'
          height='100vh'
          image={ imageOri }
          ref={ panImage }
          autoLoad={ true }
          pitch={ data.defaultCoordinate?.defaultPitch }
          yaw={ data.defaultCoordinate?.defaultYaw }
          mouseZoom={ false }
          showZoomCtrl={ false }
          onMouseup={ event => {
            // console.log(
            //   "PITCH",
            //   panImage.current.getViewer().mouseEventToCoords(event)[0]
            // );
            // console.log(
            //   "YAW",
            //   panImage.current.getViewer().mouseEventToCoords(event)[1]
            // );
          } }
        >
          { childData.map((val, i) => {
            if (val.type === "3") {
              return (
                <Pannellum.Hotspot
                  key={ i }
                  type='info'
                  pitch={ val.coordinate.pitch }
                  yaw={ val.coordinate.yaw }
                  text={ val.description }
                />
              );
            }
            if (val.type === "9") { 
              return (
                <Pannellum.Hotspot
                  key={ i }
                  type='custom'
                  pitch={ val.coordinate.pitch }
                  yaw={ val.coordinate.yaw }
                  text={ val.description }
                  tooltip={hotSpotVideo}
                  tooltipArg={val}
                />
              );
            }

            if (val.type === "10") { 
              return (
                <Pannellum.Hotspot
                  key={ i }
                  type='custom'
                  pitch={ val.coordinate.pitch }
                  yaw={ val.coordinate.yaw }
                  text={ val.description }
                  tooltip={hotspotChat}
                  tooltipArg={val}
                />
              );
            }

            return (
              <Pannellum.Hotspot
                key={ i }
                type='custom'
                pitch={ val.coordinate.pitch }
                yaw={ val.coordinate.yaw }
                tooltip={ hotspotIcon }
                tooltipArg={ val }
                handleClick={ () => handleClick(val) }
              />
            );
          }) }
        </Pannellum>
      ) }
      <div id='tester' className='panorama' />
      <ButtonChat
        liveChat={data.liveChat}
      />
      <ModalSignin
        isOpen={ isOpen }
        setIsOpen={ setIsOpen }
        handleLogin={handleLogin}
      />
      <ModalMultiType
        isOpen={ isOpenMultiType }
        className={ type === "5" ? "max-w-[70%] my-4" : "max-w-[90%] my-4" }
        setIsOpen={ () => setIsOpenMultiType(!isOpenMultiType) }
        setIsRender={ () => setIsRender(!isRender) }
        type={ type }
        isSrc={ isSrc }
      />
    </>
  );
}

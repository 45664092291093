export default function(baseApi, googleID) {
    return {
      intervals: {
        logout: 3600, // 1 hour in seconds
      },
      url: baseApi,
      api: {
        public: `${baseApi}/api/v1/public`,
        signin: `${baseApi}/api/v1/login`,
        setups: `${baseApi}/api/v1/setups`,
        ip: 'https://geolocation-db.com/json/',
      },
      google: googleID,
      token: {
        auth: process.env.AUTH_TOKEN,
      },
    };
  }
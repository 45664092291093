/* eslint-disable react/jsx-no-bind */
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { GlobalStyles } from 'constant';
// import Home from 'screens/Auth/Signin';
import SignIn from 'screens/Auth/Signin';
import Hall from 'screens/Hall';
import Stand from 'screens/Hall/stand';
import TownHall from 'screens/TownHall';
import PrivateRoute from './private';
import Page404 from 'components/atoms/404';

const publicRoutes = [
  {
    path: '/sign-in',
    component: SignIn,
    title: 'Sign in LPIK from ITB',
    content: 'Sign in lpik',
  },
];

const privateRoutes = [
  {
    path: '/main-hall',
    component: Hall,
    title: 'main hall lpik',
    content: 'main hall all stand lpik from ITB',
  },
  {
    path: '/main-hall/:id',
    component: Hall,
    title: 'main hall lpik',
    content: 'main hall all stand lpik from ITB',
  },
  {
    path: '/stand/:id',
    component: Stand,
    title: 'main hall lpik',
    content: 'main hall all stand lpik from ITB',
  },
  {
    path: '/town-hall',
    component: TownHall,
    title: 'main hall lpik',
    content: 'main hall all stand lpik from ITB',
  },
  {
    path: '/404',
    component: Page404,
    title: '404',
    content: 'server error',
  },
  {
    path: '/',
    component: () => <Redirect to='/sign-in' />,
    title: 'main hall lpik',
    content: 'main hall all stand lpik from ITB',
  },
];

interface Props {
  // eslint-disable-next-line no-undef
  history: any;
}

const Router = (props: Props) => {
  return (
    <ConnectedRouter history={ props.history }>
      <GlobalStyles />
      <Switch>
        { publicRoutes.map((route, idx) => (
          <NonAuthmiddleware
            path={ route.path }
            component={ route.component }
            title={ route.title }
            content={ route.content }
            key={ idx }
          />
        )) }
        { privateRoutes.map(route => (
          <PrivateRoute
            key={ route.path }
            exact={ true }
            path={ route.path }
            component={ route.component }
            title={ route.title }
            content={ route.content }
          />
        )) }
      </Switch>
    </ConnectedRouter>
  );
};

const NonAuthmiddleware = ({ component: Component, ...rest }) => {
  const token = Cookies.get('token');
  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{ rest.title }</title>
        <meta name={ rest.title } content={ rest.content } />
      </Helmet>
      <Route
        { ...rest }
        render={ routeProps => {
          return !token ? (
            <Component { ...routeProps } />
          ) : (
            <Redirect to={ { pathname: '/main-hall' } } />
          );
        } }
      />
    </div>
  );
};

export default Router;

/* eslint-disable arrow-parens */
import MyModal from 'components/atoms/Modals';
import {  useSelector } from 'react-redux';
import './style.css';
import DataTable from 'react-data-table-component';
import forceRedirect from '../../../../helpers/location';

const SearchHall = (props) => {
    const { isOpen, closeModal, idHall } = props;


    const dataCompany = useSelector(state => state.MainHallReducer.bannerMap);
    const clickHandler = (id) => {
        return forceRedirect(`/stand/${id}`);
    };

    const columns = [
        {
            name: 'Company',
            selector: row => row.title,
        },
        {
            cell:(row) => <button onClick={() => clickHandler(row.id)}  className="cursor-pointer bg-transparent hover:bg-blue-500 text-gray-400 font-semibold hover:text-gray-700 py-2 px-4 border border-gray-300 hover:border-transparent rounded" >Visit</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    
    const data = [
        {
            id: 1,
            title: 'Beetlejuice',
            
        },
    ];


    const getData = (idHall, dataCompany) => {
        var listOfData = [];
        if (!dataCompany) {
            return true;
        }
        for (let index = 0; index < dataCompany.length; index++) {
            const element = dataCompany[index];
            if (element.id == idHall) {
                if (!element.mainHall) {
                    return true;
                }
                for (let ii = 0; ii < element.mainHall.length; ii++) {
                    const mainHall =  element.mainHall[ii];
                    console.log(mainHall);
                  listOfData.push({ "title": mainHall.title, 'id': mainHall.childMainHallId});
                }
            }
        }
        return listOfData;
    };

  return (
    <MyModal
        isOpen={ isOpen }
        closeModal={ closeModal }
        className={ 'max-w-[90%] my-4' }
    >
        <div className='flex justify-start p-2'>
            <div
            className='cursor-pointer'
            onClick={ closeModal }
            >
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-10 w-10'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='#9CA3AF'
                >
                    <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={ 2 }
                    d='M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z'
                    />
                </svg>
            </div>
        </div>
        <div className='p-4'>
            <DataTable
                columns={ columns }
                data={ getData(idHall, dataCompany) }
            />
        </div>
    </MyModal>
  );
};

export default SearchHall;
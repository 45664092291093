/* eslint-disable react/jsx-no-bind */
import MyModal from 'components/atoms/Modals';
import { Inputs } from 'components/atoms/Inputs';
import { useFormik } from 'formik';
import {useState, React} from 'react';

export default function ModalSignin(props) {
  const { isOpen, setIsOpen, handleLogin } = props;


  const [name, setName] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [institution, setInstitution] = useState('');

  const [isName, setIsName] = useState('');
  const [isPhone, setIsPhoneNumber] = useState();
  const [isInstitution, setIsInstitution] = useState('');
  const validatePhoneNumber = (input_str) =>  {
    if (isNaN(input_str)) {
      return false;
    }
    return true;
  };

  return (
    <MyModal
        isOpen={ isOpen }
        closeModal={ setIsOpen }
        className={ 'max-w-md my-4' }
      >
        <div className='p-6'>
          <div className='flex justify-end'>
            <div className='cursor-pointer' onClick={ () => setIsOpen(!isOpen) }>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-7 w-7'
                fill='none'
                viewBox='0 0 24 24'
                stroke='#9CA3AF'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={ 2 }
                  d='M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z'
                />
              </svg>
            </div>
          </div>
          <div className='flex justify-center py-4'>Checkin To Bandung Startup Pitching Day 2022</div>
          <div className=''>
          <form className="mt-12 space-y-6" action="#" method="POST">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="name"
                  autoComplete="name"
                  required
                  className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Name"
                  onInput={e => setName(e.target.value)}
                />
                <label className="text-red-600">{isName}</label>
              </div>
              <br />
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Phone Number
                </label>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  autoComplete="phoneNumber"
                  required
                  className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Phone Number"
                  onInput={e => setPhoneNumber(e.target.value)}
                />
                <label className="text-red-600">{isPhone}</label>
              </div>
              <br />
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Institution
                </label>
                <input
                  id="institution"
                  name="institution"
                  type="text"
                  autoComplete="institution"
                  required
                  className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Institution"
                  onInput={e => setInstitution(e.target.value)}
                />
              </div>
              <label className="text-red-600">{isInstitution}</label>
            </div>
            <div>
              <button
                type="button"
                className="group relative w-full flex justify-center
                py-2 px-4 border border-transparent text-sm font-medium
                rounded-md text-white bg-indigo-600 hover:bg-indigo-700
                focus:outline-none focus:ring-2 focus:ring-offset-2
                focus:ring-indigo-500"
                onClick={ e => {
                  if (name == "") {
                    setIsName("Name is required");
                    return false;
                  }
                  setIsName("");
                  console.log(validatePhoneNumber(phone));
                  if (phone == "" || !validatePhoneNumber(phone)) {
                    setIsPhoneNumber("Phone Number is required");
                    return false;
                  }
                  setIsPhoneNumber("");
                  if (institution == "") {
                    setIsInstitution("Institution is required");
                    return false;
                  }
                  setIsInstitution("");
                  handleLogin(name, phone, institution);
              } }
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                </span>
                  Sign in
              </button>
            </div>
          </form>
            
            {/* <button
              type='button'
              onClick={ handleLogin }
              className='uppercase h-12 mt-3 text-white w-full rounded bg-red-800 hover:bg-red-900'
            >
              <i className='fa fa-google mr-2' />
              Google
            </button> */}
          </div>
        </div>
      </MyModal>
  );
}

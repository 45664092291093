/* eslint-disable quotes */
/* eslint-disable jsx-quotes */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-bind */
import { useState } from 'react';
import {  useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BsSearch } from "react-icons/bs";
import ReactTooltip from 'react-tooltip';

import MyModal from 'components/atoms/Modals';
import ImgDefault from 'assets/images/default.jpeg';
import forceRedirect from '../../../../helpers/location';
import SearchHall from './SearchHall';

export default function GaleryMap(props) {
  const { isOpen, closeModal } = props;
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [idHall, setIDHall] = useState(false);

  const data = useSelector(state => state.MainHallReducer.bannerMap);

  const handleClick = id => {
    forceRedirect(`/main-hall/${id}`);
    closeModal();
  };

  const addDefaultSrc = ev => {
    ev.target.src = ImgDefault;
  };

  return (
    <MyModal
      isOpen={ isOpen }
      closeModal={ closeModal }
      className={ 'max-w-[90%] my-4' }
    >
      <div className='flex justify-end p-2'>
        <div
          className='cursor-pointer'
          onClick={ closeModal }
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-10 w-10'
            fill='none'
            viewBox='0 0 24 24'
            stroke='#9CA3AF'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={ 2 }
              d='M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z'
            />
          </svg>
        </div>
      </div>
      <div className='grid-cols-3 p-10 space-y-2 lg:space-y-0 lg:grid lg:gap-3 lg:grid-rows-3'>
           {
            data.map((val, i) => {
              return (
                  <div key={ i }>
                      <img  onError={ addDefaultSrc } src={ val.imgBannerChild } className='rounded-md xxxxx' alt='image_banner' />
                      <div className="relative px-4 -mt-16  ">
                        <div className="bg-white p-6 rounded-lg shadow-lg">
                          <div className="flex items-baseline">
                          </div>
                          <h4 className="mt-1 text-xl font-semibold uppercase leading-tight truncate text-gray-500">  { val.label }</h4>
                        <div className="mt-4">
                        <div className='flex justify-between items-center'>
                          <button
                            onClick={ () => handleClick(val.id) }
                            className="cursor-pointer bg-transparent hover:bg-blue-500 text-gray-400 font-semibold hover:text-gray-700 py-2 px-4 border border-gray-300 hover:border-transparent rounded">
                            Visit now
                          </button>
                          <div
                            data-tip data-for='searchHall'
                            onClick={ () => {setIsOpenSearch(!isOpenSearch); setIDHall(val.id);}}
                            className='cursor-pointer' >
                            <BsSearch />
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
              );
            })
          }
      </div>
      <SearchHall
        idHall={idHall}
        isOpen={ isOpenSearch }
        closeModal={ () => setIsOpenSearch(!isOpenSearch) }
      />
      <ReactTooltip id='searchHall' type='warning' >
          <span>Search company</span>
      </ReactTooltip>
    </MyModal>
  );
}

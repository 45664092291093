/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import LoadingBar from 'assets/images/loading-chat.gif';
import imgChat from 'assets/images/baacckkk.gif';

export default function ButtonBack(props) {
  const { liveChat } = props;
  const elementChat = useSelector(state => state.MainHallReducer.data);
  let history = useHistory();

  const handleClick = () => {
    history.goBack();
  };
  
  return (
    <Fragment>
      <div className='absolute bottom-15 right-20 top-40'>
      <div className="arena-chat" data-publisher="lpik-itb" data-chatroom="lpik-itb-global" data-position="bottom"></div>
        <div
          className='cursor-pointer'
          onClick={ handleClick }
          >
          <div className="btnChat">
          <img src={imgChat} width={"100"} height={"100"} />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

/* eslint-disable no-undef */
import React, { useEffect, Fragment } from 'react';
import * as PANOLENS from 'panolens';

export default function TownHall() {
  const imgBanner = '/hall/town_hall.jpg';
  
  useEffect(() => {
    funcPanorama();
  }, []);

  const funcPanorama = () => {
    const panoID = document.querySelector('#viewer-container');
    const page = new PANOLENS.ImagePanorama(imgBanner);
    const viewer = new PANOLENS.Viewer({
      output: 'console',
      container: panoID,
      autoHideInfospot: false,
    });
    viewer.add(page);
    viewer.setCameraFov(100);
  };
  return (
    <Fragment>
      <div className='w-full h-screen relative' id='viewer-container' />
    </Fragment>
  );
}

/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import bgloading from 'assets/images/BG_loading.png';


import ButtonChat from 'components/molecules/MainHall/ButtonChat';
import './style.css';
import ShowModal from 'components/atoms/Modals/showModal';
import BottomNavbar from 'components/atoms/Button/BottomNavbar';
import { actionGetListHall, actionGetMainHall, actionSetups } from 'store/hall/actions';
import forceRedirect from '../../helpers/location';
import { Pannellum } from 'pannellum-react';
import {isMobile} from 'react-device-detect';
import { addDefaultSrc } from "helpers/onErrors";
import linkEmbeded from 'helpers/linkEmbeded';
import  environment  from 'environment';

const MainHall = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [isSrc, setSrc] = useState('');
  const [type, setType] = useState('');
  let { id } = useParams();
  const [params] = useState(id === undefined ? 'latest' : id);
  const panImage = useRef(null);
  const [mainHalls, setMainHall] = useState({});
  const [listCoordinates, setListCoordinates] = useState([]);
  const [defaultValueView, setDefaultValueView] = useState({});
  
  useEffect(() => {
    fetchMainHall();
    fetchListHall();
    fetchSetups();
  }, []);

  const fetchMainHall = useCallback(() => {
    dispatch(
      actionGetMainHall(params, val => {
        setDefaultValueView(val.defaultCoordinate);
        setMainHall(val);
        setListCoordinates(val.mainHall);
      })
    );
  }, [isRender]);

  const fetchListHall = useCallback(() => {
    dispatch(
      actionGetListHall()
    );
  }, []);

  const fetchSetups = useCallback(() => {
    dispatch(
      actionSetups()
    );
  }, []);
  
  const handleClick = (event, arg) => {
    if (arg.type === '1') {
      // move page
      // History.push(`/main-hall/${arg.childMainHallId}`);
      forceRedirect(`/main-hall/${arg.childMainHallId}`);
      return;
    } else if (arg.type === '2') {
      // show pdf
      setType(arg.type);
      setSrc(arg.link);
      setIsOpen(!isOpen);
    } else if (arg.type === '4') {
      // youtube
      setType(arg.type);
      let l = '//www.youtube.com/embed/'+linkEmbeded(arg.link)+'?autoplay=1';
      setSrc(l);
      setIsOpen(!isOpen);
    } else if (arg.type === '5') {
      // banner
      setType(arg.type);
      setSrc(arg.link);
      setIsOpen(!isOpen);
    } else if (arg.type === '6') {
      // external link
      window.open(arg.link);
    } else if (arg.type === '7') {
      // move page
      setIsRender(!isRender);
      // console.log(arg.childMainHallId);
      return forceRedirect(`/stand/${arg.childMainHallId}`);
    }
  };

  // Hot spot creation function
  const hotspotIcon = (hotSpotDiv, args) => {
    // hotSpotDiv.classList.add('pnlm-hotspot-custom');
    // var span = document.createElement('span');
    // span.innerHTML = '<div class="videoWrapper"><img style="width:'+args.width+'px;height:'+args.height+'px" src="'+args.image+'" onError="'+addDefaultSrc+'" /></div>';
    // hotSpotDiv.appendChild(span);
    // span.style.width = span.scrollWidth - 20 + 'px';
    // span.style.marginLeft = -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    // span.style.marginTop = -span.scrollHeight - 12 + 'px';
    // hotSpotDiv.classList.add('custom-tooltip');
    // const image = document.createElement("span");
    
    var image = document.createElement('img');
    image.classList.add("image");
    image.setAttribute("width", Number(args.width) + Number(100));
    image.setAttribute("height", Number(args.height) + Number(100));
    image.setAttribute("src", args.image);
    image.setAttribute("onError", addDefaultSrc);
    // image.style.width = image.scrollWidth - 20 + 'px';
    // image.style.marginLeft = -(image.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    // image.style.marginTop = -image.scrollHeight - 12 + 'px';

    // hotSpotDiv.appendChild(image);
    // hotSpotDiv.style.width = hotSpotDiv.scrollWidth - 100 + "px";
    
    hotSpotDiv.classList.add('custom-tooltip');
    var span = document.createElement('span');
    span.innerHTML = args.title;
    hotSpotDiv.appendChild(span);
    span.style.width = span.scrollWidth - 20 + 'px';
    span.style.marginLeft = -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    span.style.marginTop = -span.scrollHeight - 12 + 'px';

    hotSpotDiv.appendChild(image);


  };

  const hotSpotVideo = (hotSpotDiv, arg) => {
    // hotSpotDiv.classList.add('pnlm-hotspot-custom');
    // var span = document.createElement('span');

    // if (arg.type == "8") {
    //   span.innerHTML = '<div class="videoWrapper"><iframe width="'+arg.width+'px" height="'+arg.height+'px" src="'+arg.link+'"></iframe></div>';   
    // } else {
    //   span.innerHTML = '<div class="videoWrapper"><iframe width="'+arg.width+'px" height="'+arg.height+'px" src="//www.youtube.com/embed/'+linkEmbeded(arg.link)+'?autoplay=1"></iframe></div>';
    // }
  
    // hotSpotDiv.appendChild(span);
    // span.style.width = span.scrollWidth - 20 + 'px';
    // span.style.marginLeft = -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    // span.style.marginTop = -span.scrollHeight - 12 + 'px';


    hotSpotDiv.classList.add('pnlm-hotspot-custom');
    var span = document.createElement('span');
    if (arg.type == "9") {
      span.innerHTML = '<div class="videoWrapper"><iframe style="width:'+arg.width+'px;height:'+arg.height+'px" src="'+arg.link+'"></iframe></div>';   
    } else {
      span.innerHTML = '<div class="videoWrapper"><iframe style="width:'+arg.width+'px;height:'+arg.height+'px" src="//www.youtube.com/embed/'+linkEmbeded(arg.link)+'?autoplay=1"></iframe></div>';
    }
    hotSpotDiv.appendChild(span);
    span.style.width = span.scrollWidth - 20 + 'px';
    span.style.marginLeft = -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    span.style.marginTop = -span.scrollHeight - 12 + 'px';

  };

  let imageOri = mainHalls.imgBanner;
  if (mainHalls.imgBanner !== undefined) {
    if (isMobile) {
      imageOri = mainHalls.imgBannerChild;
    }
  }


  console.log(mainHalls);
  return (
    <div>
      <Pannellum
        id='tester'
        type="equirectangular"
        autoRotate={-2}
        compass={true}
        width='100%'
        height='100vh'
        preview={bgloading}
        image={ imageOri }
        ref={ panImage }
        pitch={ defaultValueView.defaultPitch }
        yaw={ defaultValueView.defaultYaw }
        mouseZoom={ false }
        title={mainHalls?.label}
        autoLoad
        showZoomCtrl={ false }
        onMouseup={ event => {
          // console.log(
          //   'PITCH',
          //   panImage.current.getViewer().mouseEventToCoords(event)[0]
          // );
          // console.log(
          //   'YAW',
          //   panImage.current.getViewer().mouseEventToCoords(event)[1]
          // );
        } }
      >
        { listCoordinates.map((val, i) => {
          if (val.type === 3) {
            return (
              <Pannellum.Hotspot
                key={ i }
                type='info'
                pitch={ val.coordinate.pitch }
                yaw={ val.coordinate.yaw }
                text={ val.description }
              />
            );
          }

          if (val.type === "9" ) { 
            return (
              <Pannellum.Hotspot
                key={ i }
                type='custom'
                pitch={ val.coordinate.pitch }
                yaw={ val.coordinate.yaw }
                text={ val.description }
                tooltip={hotSpotVideo}
                tooltipArg={val}
              />
            );
          }

          return (
            <Pannellum.Hotspot
              key={ i }
              type='custom'
              cssClass={"custom-hotspot"}
              pitch={ val.coordinate.pitch }
              yaw={ val.coordinate.yaw }
              tooltip={ hotspotIcon }
              text={ i }
              handleClick={ handleClick }
              tooltipArg={ val }
              handleClickArg={ val }
              createTooltipArgs={"Teser asdf as"}

            />
          );
        }) }
      </Pannellum>

      <div
        id='containerChat'
        className='text-lg text-yellow-300 absolute top-7 right-5'
      />

      <ShowModal
        isOpen={ isOpen }
        setIsOpen={ () => setIsOpen(!isOpen) }
        className={ type === '5' ? 'max-w-[70%] my-4' : 'max-w-[90%] my-4' }
        setIsRender={ () => setIsRender(!isRender) }
        type={ type }
        isSrc={ isSrc }
      />
      <ButtonChat 
        liveChat={mainHalls.liveChat}
      />
      <BottomNavbar />
    </div>
  );
};

export default MainHall;

import { applyMiddleware, createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import thunk from 'redux-thunk';

import { History as history } from 'helpers';
import mainHallReducer from './hall/reducer';
import authReducer from './auth/reducer';

const routeMiddleware = routerMiddleware(history);

const rootReducer = combineReducers({
  MainHallReducer: mainHallReducer,
  authReducer: authReducer,
	router: connectRouter(history),
});

const store = createStore(
	rootReducer,
  			composeWithDevTools(applyMiddleware(thunk, routeMiddleware)),
);

export { history, store };

import { GET_LIST_HAL_SUCCESS, GET_MAIN_HALL, GET_MAIN_HALL_SUCCESS, GET_SETUPS_SUCCESS } from './actionTypes';

const initialState = {
  loading: false,
  data: {},
  bannerMap: [],
  dataSetups: {}
};

const mainHallReducer = (state = initialState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case GET_MAIN_HALL:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_MAIN_HALL_SUCCESS:
      state = {
        ...state,
        data: action.payload
      };
      break;
    case GET_LIST_HAL_SUCCESS:
      state = {
        ...state,
        bannerMap: action.payload
      };
      break;
    case GET_SETUPS_SUCCESS:
      state = {
        ...state,
        dataSetups: action.payload
      };
      break;
  }

  return state;
};

export default mainHallReducer;

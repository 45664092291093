import { toast } from 'react-toastify';

import { customFetch } from 'helpers';
import environment from 'environment';

export const mainHall = async params => {
  try {
    const apiFetch: any = await customFetch(`${environment.api?.public}/main-hall/${params}`, 'GET', false, false);
    return apiFetch;
  } catch (error) {
    toast.error('Opsss. Something When Wrong !!!');
    throw error;
  }
};

export const stand = async(id:any) => {
  try {
    const apiFetch: any = await customFetch(`${environment.api?.public}/main-hall/${id}`, 'GET', false, false);
    return apiFetch;
  } catch (error) {
    toast.error('Opsss. Something When Wrong !!!');
    throw error;
  }
};

export const listHall = async() => {
  try {
    const apiFetch: any = await customFetch(`${environment.api?.public}/main-hall?pageType=mainHall`, 'GET', false, false);
    return apiFetch;
  } catch (error) {
    toast.error('Opsss. Something When Wrong !!!');
    throw error;
  }
};

export const setups = async() => {
  try {
    const apiFetch: any = await customFetch(`${environment.api?.setups}`, 'GET', false, false);
    return apiFetch;
  } catch (error) {
    toast.error('Opsss. Something When Wrong !!!');
    throw error;
  }
};

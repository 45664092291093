import React from 'react';
import { FadeLoader } from 'react-spinners';

export default function Loading() {
  return (
    <div className='w-full h-screen flex justify-center items-center bg-gray-700'>
      <FadeLoader color={ '#fff' }  height={ 15 } width={ 5 } />
    </div>
  );
}

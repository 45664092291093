import { SAVE_DATA_LOGIN, SAVE_IP_USERS } from './actionTypes';

const initialState = {
  pageLogin: {},
  IPv4: ''
};

const authReducer = (state = initialState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case SAVE_DATA_LOGIN:
      state = {
        ...state,
        pageLogin: action.payload
      };
      break;
    case SAVE_IP_USERS:
      state = {
        ...state,
        pageLogin: action.payload
      };
      break;
  }

  return state;
};

export default authReducer;


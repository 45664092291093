/* eslint-disable react/jsx-no-bind */
import React from 'react';
import {
  Route,
  Redirect
 } from 'react-router-dom';
 import Cookies from 'js-cookie';
 import { Helmet } from 'react-helmet';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = Cookies.get('token');
  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{ rest.title }</title>
        <meta name={ rest.title } content={ rest.content } />
      </Helmet>
      <Route
        { ...rest }
        render={ props => {
          return token ? (
              <Component { ...props } />
          ) : (
            <Redirect
              to={ {
                pathname: '/sign-in',
              } }
            />
          );
        } }
      />
    </div>
  );
};

export default PrivateRoute;

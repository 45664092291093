import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

export default function MyModal(props: any) {
  const { isOpen, closeModal, children, className } = props;

  return (
    <>
      <Transition appear show={ isOpen } as={ Fragment }>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={ closeModal }
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={ Fragment }
              enter='transition ease-in-out duration-1000'
              enterFrom='opacity-0 duration-1000'
              enterTo='opacity-100'
              leave='ease-in duration-1000'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0' />
            </Transition.Child>

            { /* This element is to trick the browser into centering the modal contents. */ }
            <span
              className='inline-block align-top'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={ Fragment }
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className={ `inline-block w-full  text-left align-middle transition-all transform bg-white shadow-xl rounded-sm ${className}` }>
                { children }
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

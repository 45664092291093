/* eslint-disable no-undef */
import { ServiceHall } from 'services';
import { GET_LIST_HAL_SUCCESS, GET_MAIN_HALL_SUCCESS, GET_SETUPS_SUCCESS } from './actionTypes';


export const actionGetMainHall = (params, callBack) => async(dispatch: any) => {
  const response = await ServiceHall.mainHall(params);
  if (response !== undefined) {
    dispatch({
      type: GET_MAIN_HALL_SUCCESS,
      payload: response
    });
    callBack(response);
  }
};

export const actionDetailStand = (id: any, callBack: any) => async(dispatch: any) => {
  const response = await ServiceHall.stand(id);
  if (response !== undefined) {
    dispatch({
      type: GET_MAIN_HALL_SUCCESS,
      payload: response
    });
    callBack(response);
  }
};

export const actionGetListHall = () => async(dispatch: any) => {
  const response = await ServiceHall.listHall();
  if (response !== undefined) {
    dispatch({
      type: GET_LIST_HAL_SUCCESS,
      payload: response.data
    });
  }
};

export const actionSetups = () => async(dispatch: any) => {
  const response = await ServiceHall.setups();
  if (response !== undefined) {
    dispatch({
      type: GET_SETUPS_SUCCESS,
      payload: response.data[0]
    });
  }
};


import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Router from 'router';
import { history, store } from 'store';

const App = () => {
  return (
    <Provider store={ store }>
      <ToastContainer />
      <Router  history={ history }/>
    </Provider>
  );
};
export default App;

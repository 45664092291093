/* eslint-disable no-undef */
// import { getToken } from './localStorage';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const getToken = () => {
  const base64 = Cookies.get('token');
  const tokenBase64 = base64 !== undefined ? atob(base64) : '';
  const data = `Bearer ${tokenBase64}`;
  return data;
};
const customFetch = async(url:string, method:string, data:any, isUpload:boolean, ipHeaders:any) => {
  const headers: any = {};

  if (isUpload) {
    headers['Accept'] = 'multipart/form-data';
  } else {
    headers['Accept'] = 'application/json; charset=utf-8';
    headers['Content-Type'] = 'application/json; charset=utf-8';
    headers['ip'] = '192.168.1.1';
  }
  headers['Authorization'] = getToken();

  try {
    const hitApi = await fetch(url, {
      method: method,
      body: isUpload
        ? data
        : method !== 'GET'
        ? JSON.stringify(data)
        : undefined,
      headers,
    });

    const response = await hitApi;
    const resJson = await response.json();
    if (response.status <= 299) {
      return Promise.resolve(resJson);
    } else if (response.status === 404) {
      toast.error(resJson.message);
      window.location.href = '/404';
    } else if (response.status === 500) {
      toast.error(resJson.message);
    } else if (response.status === 401) {
      Cookies.remove('user');
      Cookies.remove('enter');
      Cookies.remove('token');
      window.location.href = '/sign-in';
      toast.error(resJson.message);
    } else {
      toast.error(resJson.message);
    }
  } catch (err) {
    return Promise.reject(err);
  }
};
export default customFetch;

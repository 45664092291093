/* eslint-disable arrow-parens */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import forceRedirect from '../../../helpers/location';
import {
  useDispatch, useSelector,
} from 'react-redux';

import SoundMute from 'assets/images/icons8-mute-50.png';
import Sound from 'assets/images/icons-sound.png';
import ImgMap from 'assets/images/icons8-map-marker-50.png';
import ImgLinkEx from 'assets/images/icons8-link-50.png';
import IconSignOut from 'assets/images/icons8-sign-out-50.png';
import MyModal from '../Modals';
import GaleryMap from 'components/molecules/MainHall/GaleryMap';
import { actionLogout } from 'store/auth/actions';

export default function BottomNavbar() {
  const dispatch = useDispatch();
  const [playing, setPlaying] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMap, setIsOpenMap] = useState(false);
  const [active, setActive] = useState('hall');
  const playMusic = useSelector((state) => state.MainHallReducer.dataSetups);
  const [audio, setAudio] = useState(new Audio());
  
  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    setAudio(new Audio(playMusic.mp3));
  }, [playMusic.mp3]);

  const handleLogout = () => {
    dispatch(
      actionLogout(val => {
        Cookies.remove('user');
        Cookies.remove('enter');
        Cookies.remove('token');
        forceRedirect("/sign-in");
      })
    );
  };

  const redirectExternal = () => {
    window.open('https://virtualfair.itb.ac.id/', '_blank');
  };

  return (
    <div className='absolute bottom-0 left-[35%] right-[35%] rounded-tl-lg'>
      <div className=' flex justify-center items-center cursor-pointer'>
        <div
          className={ `w-full h-20 ${active === 'sound' ? 'bg-[#34D399]' : 'bg-[#9bc03c]' }  flex justify-center items-center rounded-tl-3xl` }
          onClick={ () => { setPlaying(!playing); } }
        >
          <div className='py-2 text-center'>
            <div className='text-center flex justify-center'>
              {
                !playing ? (
                  <img src={ SoundMute } className='h-8 w-8' />
                ) : (
                  <img src={ Sound } className='h-8 w-8' />
                )
              }
            </div>
            <div className='pt-2 text-white'>Sound</div>
          </div>
        </div>
        <div onClick={ () => { setActive('hall'); setIsOpenMap(!isOpenMap); } } className={ `w-full h-20 ${active === 'hall' ? 'bg-[#34D399]' : 'bg-[#9bc03c]' } flex justify-center items-center` }>
          <div
            className='py-2 text-center'>
            <div>
               <img src={ ImgMap } className='h-8 w-8' />
            </div>
            <div className='pt-2 text-white'>Hall</div>
          </div>
        </div>
        <div onClick={ () => { setActive('hall'); redirectExternal(); }} className={ `w-full h-20 ${active === 'hall' ? 'bg-[#34D399]' : 'bg-[#9bc03c]' } flex justify-center items-center` }>
          <div
            className='py-2 text-center'>
            <div>
               <img src={ ImgLinkEx } className='h-8 w-8' style={{marginLeft: "25px"}}/>
            </div>
            <div className='pt-2 text-white'>PMWxTOP</div>
          </div>
        </div>
        <div
          className={ `w-full h-20 ${active === 'signout' ? 'bg-[#34D399]' : 'bg-[#9bc03c]' } flex justify-center items-center rounded-tr-3xl` }
          onClick={ () => { setIsOpen(!isOpen); setActive('signout'); } }
        >
          <div className='py-2 text-center'>
            <div className='text-center flex justify-center w-full'>
              <img src={ IconSignOut } className='h-8 w-8'   />
            </div>
            <div className='pt-2 text-white'>Sign Out</div>
          </div>
        </div>
      </div>
      <GaleryMap
        key={ `${Math.random()}` }
        isOpen={ isOpenMap }
        closeModal={ () => setIsOpenMap(!isOpenMap) }
      />
      <MyModal
        isOpen={ isOpen }
        closeModal={ () => setIsOpen(!isOpen) }
        className={ 'max-w-xs my-4' }
      >
        <div className='flex justify-end p-4'>
          <div
            className='cursor-pointer'
            onClick={ () => setIsOpen(!isOpen) }
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-8 w-8'
              fill='none'
              viewBox='0 0 24 24'
              stroke='#9CA3AF'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={ 2 }
                d='M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z'
              />
            </svg>
          </div>
        </div>
        <div className='p-3'>
          <div className='flex justify-center'>
            <p className='text-xl'>Are you sure ?</p>
          </div>
          <div className='flex justify-center p-4'>
            <div className='w-full'>
              <button
              onClick={ handleLogout }
              className='p-2 w-full text-white border-2 rounded-md bg-blue-500'>
                Yes
              </button>
            </div>
            <div className='w-full'>
              <button
                onClick={ () => setIsOpen(!isOpen) }
                className='p-2 w-full text-white border-2 rounded-md bg-gray-400'
              >
                No
              </button>
            </div>
          </div>
        </div>
      </MyModal>
    </div>
  );
}
